@font-face {
  font-family: 'ShadowsIntoLight-Regular';
  src: url(./ShadowsIntoLight-Regular.ttf) format('truetype');
}

@keyframes bgcolor {
    0% {
        background-color: #A6EAFF
    }

    30% {
        background-color: #00FFFF
    }

    60% {
        background-color: #6EC9E5
    }

    90% {
        background-color: #D5F4FE
    }

    100% {
        background-color: #7CF3F3
    }
}

::selection {
  background: white;
  color: black;
}
  
body {
  margin: 0;
  padding: 0;
  font-family: 'ShadowsIntoLight-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #A6EAFF;
  -webkit-animation: bgcolor 20s infinite;
  animation: bgcolor 10s infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;

}
